// main: ../style.scss
/********************************
*********************************
Counters
*********************************
*********************************/
.counter-gradient {
  text-align: center;
  padding: em(30) em(20);

  .counter {
    color: $color-scheme;
    background: -webkit-linear-gradient($color-scheme, #{darken($color-scheme-dark, 10%)});
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.counter-w-info {
  margin-bottom: 10px;
  .counter {
    font-size: $h1-font-size;
    margin: 0 0.15em;
  }
  .counter-title {
    font-size: $h4-font-size;
    font-family: $headings-font-family;
    text-align: center;
    //font-weight: 300;
    line-height: 1.2;
    letter-spacing: $headings-letter-spacing;
    color: $headings-color;
  }
  .counter-info {
    text-align: center;
    font-family: $headings-font-family;
    letter-spacing: -0.06em;
    font-size: em(13);
    font-weight: 600;
    margin-top: em(5,13);
    color: $text-muted;
    .badge { font-size: inherit }
  }
}
